import React, { SyntheticEvent } from "react"
import { Grid, CheckboxProps, Responsive, GridColumn, GridRow } from "semantic-ui-react"
import {
  PaymentOptionButton,
  LaybyPaymentContainer,
  TermsConditionsCheckbox,
  PaymentOptionRadioButton,
  PaymentOptionRadio,
} from "./MakePayment.styles"
import {
  SummaryActionGrid,
  SummaryActionColumn,
} from "@components/ShoppingCart/ShoppingCart.styles"
import { KpButton } from "@elements/index"
import theme from "@styles/theme"
import { PaymentService } from "@services/PaymentService"
import { navigate } from "gatsby"
import { Checkout } from "@models/Checkout/Checkout"
import { OrderReviewSummaryData } from "@components/OrderReviewDetails/mapper"
import LaybyPayment from "./LaybyPayment"
import { OrderEntity } from "@models/OrderEntity/OrderEntity"
import { EntityTypes, DeliveryTypes } from "@models/OrderEntity/EntityType"
import InfoModal from "@components/InfoModal/InfoModal"
import { LaybyQuote } from "@models/index"
import moment from "moment"
import { BasketResponseModel } from "@models/Basket/BasketResponseModel"
import { TransactionTypesSetting } from "@models/ProductModels"
import { H4, Paragraph1, Paragraph2, SummaryGroupLabel } from "@styles/Global.styles"
import { Legal } from "@styles/Global.styles"
import { is, Record } from "immutable"
import { connect } from "react-redux"
import { Dispatch } from "redux"
import { personalDetailsValidationSchema } from "../PersonalDetailsForm/PersonalDetailsForms.validationSchema"
import { CheckCombinationService } from "@services/CheckCombinationService"
import { SalesForceService } from "@services/SalesForceService"
import { DefaultManufactureLaterAddress, PaymentOptions } from "@utils/Constant"
import AfterpayLogo from "@assets/svgs/AfterpayLogo"
import { OrderTotal } from "@components/OrderSummary/OrderTotal"
import { getCurrentCheckout, saveAllowAfterToLocalStorage } from "@redux/localStorage/checkout"
import { dealerDetailsValidationSchema } from "@components/Dealer/DealerDetailsForms.validationSchema"
import { GiftingDetails } from "@models/Checkout/GiftingDetails/GiftingDetails"
import { getBasketResponseService, validatePromoService } from "@services/BasketService"
import { response } from "express"
import { currentBasket } from "@redux/reducers"
import { savePaymentMetodToLocalStorage, setPaymentMethod } from "@redux/localStorage/basket"

interface FullPaymentProps {
  apiUrl: string
  updatePaymentType: (paymentType: number) => void
  updateIsLayby: (isLayby: boolean) => void
  updateLaybyQuote: (laybyQuote: LaybyQuote) => void
  updateIsAutopay: (isAutopay: boolean) => void
  updateLaybyPreferredPaymentDay: (paymentDay: string) => void
  basket: BasketResponseModel
  checkout: Checkout
  data: OrderReviewSummaryData
  isRetryPayment: boolean
  clearBasketAndCheckout: () => void
  clearBasket: () => void
  updateIsAcceptStatement: (isAcceptStatement: boolean) => void
}
interface FullPaymentState {
  processing: boolean
  error: string
  onErrorModalClose: Function
  showError: boolean
  ccManufaucture: boolean
  ccAgreeTC: boolean
  a2aAgreeTC: boolean
  afterpayAgreeTC: boolean
  selectedPaymentOption: number
  dealerTC: boolean,
  dealerCustomerTC: boolean,
  dealerAuthorityTC: boolean,
  dealerPaymentRequestTC: boolean,
  disableAfterpayPayment: boolean,
  gemAgreeTC: boolean
}


//PAYMENT AVAILABLE RULES
//CREDIT CARD - AVAILABLE IN ALL SCENARIOS
//ACCOUNT2ACCOUNT - NOT AVAILABLE FOR REDESIGN/UPGRADE, USER CAN PLACE ORDER WITHOUT PAYMENT
//AFTERPAY - AVAILABLE IN ALL ORDER SCENARIOS EXCEPT TOTAL ORDER AMOUNT EXCEED 20000
//LAYBY - ONLY AVAILABLE TO SINGLE NEW ORDER, PLACE ORDER IS DISABLED, WHERE USER UNABLE TO PROCEED ANY FURTHER

/* eslint-disable  jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
class FullPaymentContainer extends React.Component<
  FullPaymentProps,
  FullPaymentState
  > {
  state = {
    processing: false,
    error: "",
    onErrorModalClose: () => {
      this.props.clearBasketAndCheckout()
      navigate(`/payment-failure/?err=${this.state.error}`)
    },
    ccManufaucture: false,
    ccAgreeTC: false,
    a2aAgreeTC: false,
    afterpayAgreeTC: false,
    showError: false,
    selectedPaymentOption: this.props.checkout.paymentType == 1 && this.props.checkout.isLayby ? PaymentOptions.KiwiplatesLayby : this.props.checkout.paymentType,
    dealerTC: false,
    dealerCustomerTC: false,
    dealerAuthorityTC: false,
    dealerPaymentRequestTC: false,
    showStatement: false,
    disableAfterpayPayment: false,
    gemAgreeTC: false
  }
  componentDidMount() {
    const currentCheckout = getCurrentCheckout()
    var checkoutValidationSchema = currentCheckout.isDealerOrder ? dealerDetailsValidationSchema : personalDetailsValidationSchema

    checkoutValidationSchema
      .isValid(this.props.checkout)
      .then((valid: boolean) => {
        if (this.props.basket.basketLines.length > 0 && !valid) {
          this.setState({
            error:
              "Uh oh! Looks like you haven't filled in your details correctly",
            onErrorModalClose: () => {
              navigate("/personal-details/")
            },
          })
        }
      })

    for (let i = 0; i < this.props.basket.basketLines.length; i++) {
      const bl = this.props.basket.basketLines[i]

      this.checkCombinationAvailable(bl.combination, bl.vehicleTypeId).then(available => {
        if (!available && bl.transactionTypeId == 1) {
          this.setState({
            error:
              "Uh oh! The combination(s) you selected have just been sold.",
            onErrorModalClose: () => {
              navigate("/shopping-cart")
            },
          })
        }
      })
    }

    if(this.props.basket.summary.promotionCode){
      const isAfterpayAvailable = this.props.basket.summary.itemTotal < 2000;
      const orderHasPromo = this.props.basket.summary.discount !== 0
      validatePromoService(this.props.apiUrl, this.props.basket.summary.promotionCode, this.props.basket.basketId).then((data: any) => {
        if (data.IsValid && data.PaymentMethods) {
          if(data.PaymentMethods.toLowerCase().includes("afterpay")) {
            saveAllowAfterToLocalStorage('true')
            this.setState({disableAfterpayPayment: false})
          } else {
            saveAllowAfterToLocalStorage('')
            this.setState({disableAfterpayPayment: true})
          }
        } else{
          saveAllowAfterToLocalStorage('')
          this.setState({disableAfterpayPayment: !isAfterpayAvailable || orderHasPromo})
        }
      })
    }

  }

  checkCombinationAvailable = (combination: string, vehicleType: number) => {
    return CheckCombinationService.checkCombination(
      this.props.apiUrl,
      combination,
      vehicleType
    ).then(combinationCheckResult => {
      return combinationCheckResult.Available
    })
  }
  changeAutopay = (isAutopay: boolean) => {
    this.props.updateIsAutopay(isAutopay)
    if (this.state.showError) {
      this.setState({
        showError: this.showCheckboxError(
          this.state.ccAgreeTC,
          this.state.ccManufaucture,
          this.state.a2aAgreeTC,
          this.state.afterpayAgreeTC,
          isAutopay,
          this.state.selectedPaymentOption,
          this.state.dealerTC,
          this.state.dealerCustomerTC,
          this.state.dealerAuthorityTC,
          this.state.dealerPaymentRequestTC,
          this.state.gemAgreeTC
        ),
      })
    }
  }
  changeCCManufacture = (event: SyntheticEvent, data: CheckboxProps) => {
    const isChecked = data.checked ? data.checked : false
    this.setState({
      ccManufaucture: isChecked,
      showError: this.state.showError
        ? this.showCheckboxError(
          this.state.ccAgreeTC,
          isChecked,
          this.state.a2aAgreeTC,
          this.state.afterpayAgreeTC,
          this.props.checkout.isAutopay,
          this.state.selectedPaymentOption,
          this.state.dealerTC,
          this.state.dealerCustomerTC,
          this.state.dealerAuthorityTC,
          this.state.dealerPaymentRequestTC,
          this.state.gemAgreeTC
        )
        : this.state.showError,
    })
  }
  changeGemAgreeTC = (event: SyntheticEvent, data: CheckboxProps) => {
    const isChecked = data.checked ? data.checked : false
    this.setState({
      gemAgreeTC: isChecked,
      showError: this.state.showError
        ? this.showCheckboxError(
          this.state.ccAgreeTC,
          this.state.ccManufaucture,
          this.state.a2aAgreeTC,
          this.state.afterpayAgreeTC,
          this.props.checkout.isAutopay,
          this.state.selectedPaymentOption,
          this.state.dealerTC,
          this.state.dealerCustomerTC,
          this.state.dealerAuthorityTC,
          this.state.dealerPaymentRequestTC,
          isChecked
        )
        : this.state.showError,
    })
  }
  changeCCAgreeTC = (event: SyntheticEvent, data: CheckboxProps) => {
    const isChecked = data.checked ? data.checked : false
    this.setState({
      ccAgreeTC: isChecked,
      showError: this.state.showError
        ? this.showCheckboxError(
          isChecked,
          this.state.ccManufaucture,
          this.state.a2aAgreeTC,
          this.state.afterpayAgreeTC,
          this.props.checkout.isAutopay,
          this.state.selectedPaymentOption,
          this.state.dealerTC,
          this.state.dealerCustomerTC,
          this.state.dealerAuthorityTC,
          this.state.dealerPaymentRequestTC,
          this.state.gemAgreeTC
        )
        : this.state.showError,
    })
  }
  changeAfterpayAgreeTC = (event: SyntheticEvent, data: CheckboxProps) => {
    const isChecked = data.checked ? data.checked : false
    this.setState({
      afterpayAgreeTC: isChecked,
      showError: this.state.showError
        ? this.showCheckboxError(
          this.state.ccAgreeTC,
          this.state.ccManufaucture,
          this.state.a2aAgreeTC,
          isChecked,
          this.props.checkout.isAutopay,
          this.state.selectedPaymentOption,
          this.state.dealerTC,
          this.state.dealerCustomerTC,
          this.state.dealerAuthorityTC,
          this.state.dealerPaymentRequestTC,
          this.state.gemAgreeTC
        )
        : this.state.showError,
    })
  }
  changeA2AAgreeTC = (event: SyntheticEvent, data: CheckboxProps) => {
    const isChecked = data.checked ? data.checked : false
    this.setState({
      a2aAgreeTC: isChecked,
      showError: this.state.showError
        ? this.showCheckboxError(
          this.state.ccAgreeTC,
          this.state.ccManufaucture,
          isChecked,
          this.state.afterpayAgreeTC,
          this.props.checkout.isAutopay,
          this.state.selectedPaymentOption,
          this.state.dealerTC,
          this.state.dealerCustomerTC,
          this.state.dealerAuthorityTC,
          this.state.dealerPaymentRequestTC,
          this.state.gemAgreeTC
        )
        : this.state.showError,
    })
  }

  mapToOrderEntity = (checkout: Checkout, basket: BasketResponseModel) => {
    let entities: OrderEntity[] = []
    if (checkout.isMultiPlate && basket.basketLines.length >1) {
      const applicant: OrderEntity = {
        EntityTypeId: EntityTypes.Applicant,
        Title: checkout.applicantDetails.title,
        FirstName: checkout.applicantDetails.firstName,
        LastName: checkout.applicantDetails.lastName,
        MobilePhone: checkout.applicantDetails.phone,
        Email: checkout.applicantDetails.email,
        AddressLine1: DefaultManufactureLaterAddress.building,
        AddressLine2: DefaultManufactureLaterAddress.unit,
        AddressLine3: DefaultManufactureLaterAddress.street,
        Suburb: DefaultManufactureLaterAddress.suburb,
        City: DefaultManufactureLaterAddress.city,
        PostCode: DefaultManufactureLaterAddress.postCode,
      }
      entities.push(applicant)
    } else if (!checkout.isMultiPlate && basket.basketLines.length == 1){
      if (checkout.manufactureNow) {
        const dateOfBirth =
          checkout.vehicleDetails.dob === null
            ? undefined
            : moment(checkout.vehicleDetails.dob).format("YYYY-MM-DD")
        if (!checkout.vehicleDetails.vehicleMake || !checkout.vehicleDetails.vehicleModel){
          return
        }
        const owner: OrderEntity = {
          EntityTypeId: EntityTypes.Owner,
          Title: (checkout.isDealerOrder && checkout.ownershipType == "Company") ? "" : checkout.ownerDetails.title,
          FirstName: checkout.ownerDetails.firstName,
          LastName: checkout.ownerDetails.lastName,
          CompanyNumber: checkout.companyDetails.companyNumber,
          Organisation: checkout.companyDetails.companyOwner,
          MobilePhone: checkout.ownerDetails.phone,
          Email: checkout.ownerDetails.email,
          AddressLine1: checkout.ownerAddress.building,
          AddressLine2: checkout.ownerAddress.unit,
          AddressLine3: checkout.ownerAddress.street,
          Suburb: checkout.ownerAddress.suburb,
          City: checkout.ownerAddress.city,
          PostCode: checkout.ownerAddress.postCode,
          DOB: dateOfBirth,
          Licence: checkout.vehicleDetails.driverLicense,
          Make: checkout.vehicleDetails.vehicleMake,
          Model: checkout.vehicleDetails.vehicleModel,
          ExistingCombination: (!checkout.vehicleDetails.existingCombination && checkout.vehicleDetails.vosa && checkout.vehicleDetails.vosa.length > 0) ? 'VOSA *' : checkout.vehicleDetails.existingCombination,
          VehicleOwnerName: checkout.ownershipType == "Company" ? checkout.companyDetails.companyOwner : checkout.ownerDetails.firstName +
            " " + checkout.ownerDetails.lastName,
          VosaUrls: JSON.stringify(checkout.vehicleDetails.vosa)
        }
        entities.push(owner)

        if(checkout.isDealerOrder){
          const delivery: OrderEntity = {
            EntityTypeId: EntityTypes.Delivery,
            Title: checkout.deliveryAddress.title,
            FirstName: checkout.deliveryAddress.sameAsOwnerAddress && checkout.dealerDetails?.salesPersonName
              ? checkout.dealerDetails?.salesPersonName.split(' ')[0]
              : checkout.deliveryAddress.firstName,
            LastName: checkout.deliveryAddress.sameAsOwnerAddress && checkout.dealerDetails?.salesPersonName
              ? checkout.dealerDetails?.salesPersonName.substring(checkout.dealerDetails?.salesPersonName.split(' ')[0].length)
              : checkout.deliveryAddress.lastName,
            MobilePhone: '',
            Email: checkout.dealerDetails?.salesPersonEmail,
            CompanyNumber: '',
            Organisation: checkout.deliveryAddress.companyName? checkout.deliveryAddress.companyName : '',
            AddressLine1: checkout.deliveryAddress
              ? checkout.deliveryAddress.building
              : checkout.dealerAddress.building,
            AddressLine2: checkout.deliveryAddress
              ? checkout.deliveryAddress.unit
              : checkout.dealerAddress.unit,
            AddressLine3: checkout.deliveryAddress
              ? checkout.deliveryAddress.street
              : checkout.dealerAddress.street,
            Suburb: checkout.deliveryAddress
              ? checkout.deliveryAddress.suburb
              : checkout.dealerAddress.suburb,
            City: checkout.deliveryAddress
              ? checkout.deliveryAddress.city
              : checkout.dealerAddress.city,
            PostCode: checkout.deliveryAddress
              ? checkout.deliveryAddress.postCode
              : checkout.dealerAddress.postCode,
          }
          entities.push(delivery)
        } else {
          const delivery: OrderEntity = {
            EntityTypeId: EntityTypes.Delivery,
            Title: checkout.deliveryAddress.sameAsOwnerAddress
              ? checkout.ownerDetails.title
              : checkout.deliveryAddress.title,
            FirstName: checkout.deliveryAddress.sameAsOwnerAddress
              ? checkout.ownerDetails.firstName
              : checkout.deliveryAddress.firstName,
            LastName: checkout.deliveryAddress.sameAsOwnerAddress
              ? checkout.ownerDetails.lastName
              : checkout.deliveryAddress.lastName,
            MobilePhone: checkout.ownerDetails.phone,
            Email: checkout.ownerDetails.email,
            CompanyNumber: '',
            Organisation: checkout.deliveryAddress.companyName? checkout.deliveryAddress.companyName : '',
            AddressLine1: checkout.deliveryAddress.sameAsOwnerAddress
              ? checkout.ownerAddress.building
              : checkout.deliveryAddress.building,
            AddressLine2: checkout.deliveryAddress.sameAsOwnerAddress
              ? checkout.ownerAddress.unit
              : checkout.deliveryAddress.unit,
            AddressLine3: checkout.deliveryAddress.sameAsOwnerAddress
              ? checkout.ownerAddress.street
              : checkout.deliveryAddress.street,
            Suburb: checkout.deliveryAddress.sameAsOwnerAddress
              ? checkout.ownerAddress.suburb
              : checkout.deliveryAddress.suburb,
            City: checkout.deliveryAddress.sameAsOwnerAddress
              ? checkout.ownerAddress.city
              : checkout.deliveryAddress.city,
            PostCode: checkout.deliveryAddress.sameAsOwnerAddress
              ? checkout.ownerAddress.postCode
              : checkout.deliveryAddress.postCode,
          }
          entities.push(delivery)
        }

      } else {
        //this is a OnRequest Order
        if (checkout.ownershipType === "Individual") {
          const owner: OrderEntity = {
            EntityTypeId: EntityTypes.Owner,
            Title: checkout.ownerDetails.title,
            FirstName: checkout.ownerDetails.firstName,
            LastName: checkout.ownerDetails.lastName,
            MobilePhone: checkout.ownerDetails.phone,
            Email: checkout.ownerDetails.email,
            AddressLine1: DefaultManufactureLaterAddress.building,
            AddressLine2: DefaultManufactureLaterAddress.unit,
            AddressLine3: DefaultManufactureLaterAddress.street,
            Suburb: DefaultManufactureLaterAddress.suburb,
            City: DefaultManufactureLaterAddress.city,
            PostCode: DefaultManufactureLaterAddress.postCode,
          }
          entities.push(owner)
        } else {
          const owner: OrderEntity = {
            EntityTypeId: EntityTypes.Owner,
            Title: (checkout.isDealerOrder && checkout.ownershipType == "Company") ? "" : checkout.ownerDetails.title,
            FirstName: checkout.ownerDetails.firstName,
            LastName: checkout.ownerDetails.lastName,
            CompanyNumber: checkout.companyDetails.companyNumber,
            Organisation: checkout.companyDetails.companyOwner,
            MobilePhone: checkout.ownerDetails.phone,
            Email: checkout.ownerDetails.email,
            AddressLine1: DefaultManufactureLaterAddress.building,
            AddressLine2: DefaultManufactureLaterAddress.unit,
            AddressLine3: DefaultManufactureLaterAddress.street,
            Suburb: DefaultManufactureLaterAddress.suburb,
            City: DefaultManufactureLaterAddress.city,
            PostCode: DefaultManufactureLaterAddress.postCode,
          }
          entities.push(owner)
        }
      }
      if (!checkout.ownerIsApplicant && !checkout.isDealerOrder) {
        const applicant: OrderEntity = {
          EntityTypeId: EntityTypes.Applicant,
          Title: checkout.applicantDetails.title,
          FirstName: checkout.applicantDetails.firstName,
          LastName: checkout.applicantDetails.lastName,
          MobilePhone: checkout.applicantDetails.phone,
          Email: checkout.applicantDetails.email,
        }
        entities.push(applicant)
      }

      if(checkout.isDealerOrder){
        const applicant: OrderEntity = {
          EntityTypeId: EntityTypes.Dealer,
          FirstName: checkout.dealerDetails?.salesPersonName ? checkout.dealerDetails?.salesPersonName.split(' ')[0] : "",
          LastName: checkout.dealerDetails?.salesPersonName ? checkout.dealerDetails?.salesPersonName.substring(checkout.dealerDetails?.salesPersonName.split(' ')[0].length) : "",
          Email: checkout.dealerDetails?.salesPersonEmail,
          SalesPersonId: checkout.dealerDetails?.salesPersonId,
          DealerId: checkout.dealerDetails?.dealerId
        }
        entities.push(applicant)
      }
    }
    return entities
  }

  mapToGiftingDetails = (checkout: Checkout, basket: BasketResponseModel) => {
    let giftingDetails: GiftingDetails
    if(checkout.giftingType > 0 && basket.basketLines.length == 1){
      giftingDetails = {
        From: checkout.giftingDetails.from,
        To: checkout.giftingDetails.to,
        Message: checkout.giftingDetails.message,
        IsForMe: checkout.giftingDetails.isForMe,
        GiftingType: checkout.giftingType
      }
    } else{
      giftingDetails = {
        From: checkout.giftingDetails.from,
        To: checkout.giftingDetails.to,
        Message: checkout.giftingDetails.message,
        IsForMe: checkout.giftingDetails.isForMe,
        GiftingType: 0
      }
    }
    return giftingDetails
  }

  mapToDeliveryType = (ownershipType: string, manufactureNow: boolean) => {
    if (manufactureNow) {
      if (ownershipType === "Individual") {
        return DeliveryTypes.Individual
      } else {
        return DeliveryTypes.Company
      }
    } else {
      return DeliveryTypes.OnRequest
    }
  }

  placeOrder = () => {
    if(this.props.checkout.isDealerOrder && !this.props.checkout.isAcceptStatement){
      navigate("/dealer-statement");
    } else {
      const checkboxTicked = this.showCheckboxError(
        this.state.ccAgreeTC,
        this.state.ccManufaucture,
        this.state.a2aAgreeTC,
        this.state.afterpayAgreeTC,
        this.props.checkout.isAutopay,
        this.state.selectedPaymentOption,
        this.state.dealerTC,
        this.state.dealerCustomerTC,
        this.state.dealerAuthorityTC,
        this.state.dealerPaymentRequestTC,
        this.state.gemAgreeTC
      );
      if (!checkboxTicked) {
        this.setState({
          processing: true,
          showError: false,
        })

        const basketId = this.props.basket.basketId

        const entities = this.mapToOrderEntity(this.props.checkout, this.props.basket)
        const giftingDetails = this.mapToGiftingDetails(this.props.checkout, this.props.basket)
        const deliveryType = this.mapToDeliveryType(
          this.props.checkout.ownershipType,
          this.props.checkout.manufactureNow
        )
        if(!entities || entities.length < 1) {
          const message = "Failed to place your order. Please contact customer service by phone or via livechat."
          this.setState({ error: message, processing: false })
          return
        }
        const isLayby = this.props.checkout.isLayby
        const isAutoPay = this.props.checkout.isAutopay
        const isCreditCardPayment = this.props.checkout.paymentType === 1
        let amount: number | undefined = undefined

        getBasketResponseService(this.props.apiUrl, this.props.basket.basketId).then((response) =>{
          let hasError = false;
          if(response.success){
            if(response.basket){
              if(response.basket.basketLines.length != this.props.basket.basketLines.length || response.basket.summary.finalTotal != this.props.basket.summary.finalTotal){
                hasError = true;
              }
            } else{
              hasError = true;
            }
          } else{
            hasError = true;
          }

          if(hasError){
            const message =
                "Failed to place your order. Please contact customer service by phone or via livechat."
                this.setState({ error: message, processing: false })
                return;
          }

          if ((this.state.selectedPaymentOption == PaymentOptions.Account2Account && this.disablePaymentButton())
          || this.state.selectedPaymentOption == PaymentOptions.DealerPaymentRequest) {
            PaymentService.placeOrder(
              this.props.apiUrl,
              this.props.basket.basketId,
              this.mapToDeliveryType(
                this.props.checkout.ownershipType,
                this.props.checkout.manufactureNow
              ),
              this.props.checkout.isLayby,
              this.props.checkout.isAutopay,
              this.props.checkout.laybyQuote.bracketName,
              entities,
              giftingDetails,
              moment(this.props.checkout.laybyPreferredPaymentDay).format(
                "MM/DD/yyyy"
              )
            )
              .then(response => {
                if (response.Success) {
                  if (response.Data.Success) {
                    const receiptNumber = parseInt(response.Data.OrderToken);
                    const key = response.Data.Key;
                    navigate("/order-confirmation?success=1&rcpt=" + receiptNumber + "&key=" + key, { replace: true })
                  } else {
                    this.setState({
                      processing: false,
                      error:
                        "Failed to Place your order. Please contact kiwiplates customer service by phone or via livechat.",
                    })
                  }
                } else {
                  const message =
                    "Failed to place your order. Please contact customer service by phone or via livechat."

                  this.setState({ error: message, processing: false })
                }
              })
              .catch(err => {
                const message =
                  "Failed to place your order. Please contact customer service by phone or via livechat."
                console.log(err)
                this.setState({ error: message, processing: false })
              })
          }
          else {
            if (this.props.checkout.isLayby) {
              amount = this.props.checkout.laybyQuote.depositAmount
            }
            if (this.props.isRetryPayment) {
              this.goToPayment(basketId, amount)
            } else {
              PaymentService.placeOrder(
                this.props.apiUrl,
                basketId,
                deliveryType,
                isLayby,
                isAutoPay,
                this.props.checkout.laybyQuote.bracketName,
                entities,
                giftingDetails,
                moment(this.props.checkout.laybyPreferredPaymentDay).format(
                  "MM/DD/yyyy"
                )
              )
                .then(response => {
                  if (response.Success) {
                    if (response.Data.Success) {
                      // const opportunity = SalesForceService.mapSalesForceOpportunityObject(
                      //   this.props.apiUrl,
                      //   this.props.checkout,
                      //   this.props.basket
                      // )
                      // opportunity.opportunity.StageName = "ProceedToPayment"
                      // SalesForceService.syncOpportunity(
                      //   this.props.apiUrl,
                      //   basketId,
                      //   opportunity
                      // )
                      this.goToPayment(basketId, amount)
                    } else {
                      this.setState({
                        processing: false,
                        error:
                          "Failed to Place your order. Please contact kiwiplates customer service by phone or via livechat.",
                      })
                    }
                  } else {
                    const message =
                      "Failed to place your order. Please contact customer service by phone or via livechat."

                    this.setState({ error: message, processing: false })
                  }
                })
                .catch(err => {
                  const message =
                    "Failed to place your order. Please contact customer service by phone or via livechat."
                  console.log(err)
                  this.setState({ error: message, processing: false })
                })
            }
          }
        })

      } else {
        this.setState({
          showError: true,
        })
      }
    }
  }

  goToPayment = (basketId: string, amount: number | undefined) => {
    if (this.state.selectedPaymentOption == PaymentOptions.Afterpay) {
      setPaymentMethod(this.state.selectedPaymentOption)
      PaymentService.getPaymentFromAfterpayRequest(
        this.props.apiUrl,
        basketId,
        amount
      ).then(response => {
        if (response && response.StatusCode == 201) {
          this.props.clearBasketAndCheckout();
          window.location.replace(response.Result.redirectCheckoutUrl);
        } else {
          this.setState({
            error: "Payment request failed.",
            processing: false,
          })
        }
      })
    } else {
      var paymentType = this.props.checkout.paymentType
      setPaymentMethod(paymentType)
      if(paymentType === PaymentOptions.Gem){
        paymentType = PaymentOptions.CreditCard
      }

      PaymentService.paymentOnlineRequest(
        this.props.apiUrl,
        basketId,
        paymentType,
        amount
      ).then(response => {
        if (response && response.url) {
          const isDealerOrder = getCurrentCheckout().isDealerOrder
          if(isDealerOrder) {
            this.props.updateIsAcceptStatement(false)
            this.props.clearBasket()
          } else{
            this.props.clearBasketAndCheckout()
          }

          window.location.replace(response.url)
        } else {
          this.setState({
            error: "Payment request failed.",
            processing: false,
          })
        }
      })
    }

  }


  switchPaymentOptions = (paymentOption: number) => {
    this.setState({
      selectedPaymentOption: paymentOption, showError: false, ccManufaucture: false,
      ccAgreeTC: false,
      a2aAgreeTC: false,
      gemAgreeTC: false
    });
    if (paymentOption !== PaymentOptions.KiwiplatesLayby) {
      if (this.props.checkout.isLayby) {
        this.switchIsLayby(false);
      }
    } this.setState({

    })
    switch (paymentOption) {
      case PaymentOptions.CreditCard:
        savePaymentMetodToLocalStorage('Credit Card')
        this.switchPaymentType(1)
        break;
      case PaymentOptions.Account2Account:
        savePaymentMetodToLocalStorage('A2A')
        this.switchPaymentType(2)
        break;
      case PaymentOptions.Afterpay:
        savePaymentMetodToLocalStorage('Afterpay')
        this.switchPaymentType(3)
        break;
      case PaymentOptions.KiwiplatesLayby:
        savePaymentMetodToLocalStorage('Layby')
        this.switchIsLayby(true);
        break;
      case PaymentOptions.DealerPaymentRequest:
        savePaymentMetodToLocalStorage('DealerPaymentRequest')
        this.switchPaymentType(5)
        break;
      case PaymentOptions.Gem:
        savePaymentMetodToLocalStorage('Gem')
        this.switchPaymentType(6)
        break;
      default:
        break;
    }
  }

  switchPaymentType = (paymentType: number) => {

    this.props.updatePaymentType(paymentType)
  }
  switchIsLayby = (isLayby: boolean) => {
    this.props.updateIsLayby(isLayby)
    this.props.updatePaymentType(1)
  }
  goBackClick = () => {
    const checkoutState = this.props.checkout
    if (
      checkoutState.isLayby ||
      !checkoutState.ownerIsApplicant ||
      checkoutState.isMultiPlate ||
      !checkoutState.manufactureNow
    ) {
      navigate("/personal-details/")
    } else {
      navigate("/delivery-address-selection/")
    }
  }

  isLaybyAllowed = (basket: BasketResponseModel) => {
    //IF CONTAINS REDESIGN / UPGRADE OR MULTIPLE, THEN ONLY FULL PAYMENT AVAILABLE
    if (basket.summary.discount !== 0) {
      return false
    }
    if (basket.basketLines.length > 1) {
      return false
    } else {
      if (basket.basketLines.length === 1) {
        const item = basket.basketLines[0]
        if (
          item.transactionTypeId === TransactionTypesSetting.Redesign.id ||
          item.transactionTypeId === TransactionTypesSetting.Upgrade.id
        )
          return false
        else return true
      } else {
        return true
      }
    }
  }
  showCheckboxError = (
    ccAgreeTC: boolean,
    ccManufaucture: boolean,
    a2aAgreeTC: boolean,
    afterpayAgreeTC: boolean,
    isAutopay: boolean,
    selectedPaymentOption: number,
    dealerTC: boolean,
    dealerCustomerTC: boolean,
    dealerAuthorityTC: boolean,
    dealerPaymentRequestTC: boolean,
    gemAgreeTC: boolean
  ): boolean => {
    const { checkout } = this.props
    let showError = false
    if (selectedPaymentOption === PaymentOptions.CreditCard) {
      if(checkout.isDealerOrder){
        if(checkout.manufactureNow){
            showError = !dealerTC
        } else {
            showError = !dealerTC || !ccManufaucture
        }
      } else {
        if (checkout.manufactureNow) {
          showError = !ccAgreeTC
        } else {
          showError = !ccAgreeTC || !ccManufaucture
        }
      }
    } else if (selectedPaymentOption === PaymentOptions.Account2Account) {
      if (checkout.manufactureNow) {
        showError = !a2aAgreeTC
      } else {
        showError = !a2aAgreeTC || !ccManufaucture
      }
    } else if (selectedPaymentOption === PaymentOptions.Gem) {
      if(checkout.isDealerOrder){
        if(checkout.manufactureNow){
            showError = !dealerTC
        } else {
            showError = !dealerTC || !ccManufaucture
        }
      } else {
        if (checkout.manufactureNow) {
          showError = !gemAgreeTC
        } else {
          showError = !gemAgreeTC || !ccManufaucture
        }
      }
    } else if (selectedPaymentOption === PaymentOptions.Afterpay) {
      if (checkout.manufactureNow) {
        showError = !afterpayAgreeTC;
      } else {
        showError = !afterpayAgreeTC || !ccManufaucture;
      }
    } else if (selectedPaymentOption === PaymentOptions.KiwiplatesLayby && !showError) {
      showError = !isAutopay
    } else if (selectedPaymentOption === PaymentOptions.DealerPaymentRequest){
      if (checkout.manufactureNow) {
        showError = !dealerPaymentRequestTC || !dealerTC
      } else {
        showError = !dealerPaymentRequestTC || !ccManufaucture || !dealerTC
      }
    }
    return showError
  }

  disablePaymentButton = () => {
    let disablePayment = false;
    const containsUpgradeRedesign = this.props.basket.basketLines.filter(b => b.transactionTypeId !== 1).length > 0
    if (this.state.selectedPaymentOption == PaymentOptions.Account2Account) {
      if (containsUpgradeRedesign) {
        disablePayment = true;
      }
    } else if (this.state.selectedPaymentOption == PaymentOptions.Afterpay) {
      disablePayment = this.state.disableAfterpayPayment;
    } else if (this.state.selectedPaymentOption == PaymentOptions.KiwiplatesLayby) {
      disablePayment = !this.isLaybyAllowed(this.props.basket);
    }
    return disablePayment;
  }

  changeDealerTC = (event: SyntheticEvent, data: CheckboxProps) => {
    const isChecked = data.checked ? data.checked : false
    this.setState({
      dealerTC: isChecked,
      showError: this.state.showError
        ? this.showCheckboxError(
          this.state.ccAgreeTC,
          this.state.ccManufaucture,
          this.state.a2aAgreeTC,
          this.state.afterpayAgreeTC,
          this.props.checkout.isAutopay,
          this.state.selectedPaymentOption,
          isChecked,
          this.state.dealerCustomerTC,
          this.state.dealerAuthorityTC,
          this.state.dealerPaymentRequestTC,
          this.state.gemAgreeTC
        )
        : this.state.showError,
    })
  }

  changeDealerCustomerTC = (event: SyntheticEvent, data: CheckboxProps) => {
    const isChecked = data.checked ? data.checked : false
    this.setState({
      dealerCustomerTC: isChecked,
      showError: this.state.showError
        ? this.showCheckboxError(
          this.state.ccAgreeTC,
          this.state.ccManufaucture,
          this.state.a2aAgreeTC,
          this.state.afterpayAgreeTC,
          this.props.checkout.isAutopay,
          this.state.selectedPaymentOption,
          this.state.dealerTC,
          isChecked,
          this.state.dealerAuthorityTC,
          this.state.dealerPaymentRequestTC,
          this.state.gemAgreeTC
        )
        : this.state.showError,
    })
  }

  changeDealerAuthorityTC = (event: SyntheticEvent, data: CheckboxProps) => {
    const isChecked = data.checked ? data.checked : false
    this.setState({
      dealerAuthorityTC: isChecked,
      showError: this.state.showError
        ? this.showCheckboxError(
          this.state.ccAgreeTC,
          this.state.ccManufaucture,
          this.state.a2aAgreeTC,
          this.state.afterpayAgreeTC,
          this.props.checkout.isAutopay,
          this.state.selectedPaymentOption,
          this.state.dealerTC,
          this.state.dealerCustomerTC,
          isChecked,
          this.state.dealerPaymentRequestTC,
          this.state.gemAgreeTC
        )
        : this.state.showError,
    })
  }

  changeDealerPaymentRequestTC = (event: SyntheticEvent, data: CheckboxProps) => {
    const isChecked = data.checked ? data.checked : false
    this.setState({
      dealerPaymentRequestTC: isChecked,
      showError: this.state.showError
        ? this.showCheckboxError(
          this.state.ccAgreeTC,
          this.state.ccManufaucture,
          this.state.a2aAgreeTC,
          this.state.afterpayAgreeTC,
          this.props.checkout.isAutopay,
          this.state.selectedPaymentOption,
          this.state.dealerTC,
          this.state.dealerCustomerTC,
          this.state.dealerAuthorityTC,
          isChecked,
          this.state.gemAgreeTC
        )
        : this.state.showError,
    })
  }

  render() {
    const { checkout, basket } = this.props
    const tcLabel = checkout.isMultiPlate
      ? this.props.data.tcMO
      : this.props.data.tc;
    const isAfterpayAvailable = basket.summary.itemTotal < 2000;
    const orderHasPromo = basket.summary.discount !== 0
    const disablePaymentButton = this.disablePaymentButton();
    const isDealerOrder = checkout?.isDealerOrder

    return (
      <Grid>
        <Responsive as={GridRow} style={{ backgroundColor: 'lightgray', borderRadius: '5px' }} fluid width={16} minWidth={theme.responsive.tablet.minWidth}>
          <Grid.Column width={5}>
            <PaymentOptionButton
              onClick={() => this.switchPaymentOptions(PaymentOptions.CreditCard)}
              active={this.state.selectedPaymentOption === PaymentOptions.CreditCard}
            >
              Credit/Debit Card
            </PaymentOptionButton>
          </Grid.Column>
          <Grid.Column width={5}>
            <PaymentOptionButton
              onClick={() => this.switchPaymentOptions(PaymentOptions.Gem)}
              active={this.state.selectedPaymentOption === PaymentOptions.Gem}
            >
              Gem Visa
            </PaymentOptionButton>
          </Grid.Column>
          {isDealerOrder ?
          <Grid.Column width={5}>
            <PaymentOptionButton
              onClick={() => this.switchPaymentOptions(PaymentOptions.DealerPaymentRequest)}
              active={this.state.selectedPaymentOption === PaymentOptions.DealerPaymentRequest}
            >
              Dealer Payment Request
            </PaymentOptionButton>
          </Grid.Column> :
          <>
            <Grid.Column width={5}>
              <PaymentOptionButton
                onClick={() => this.switchPaymentOptions(PaymentOptions.Afterpay)}
                active={this.state.selectedPaymentOption === PaymentOptions.Afterpay}
              >
                <AfterpayLogo fill={"#000"} />
              </PaymentOptionButton>
            </Grid.Column>
            <Grid.Column width={5}>
              <PaymentOptionButton
                style={{marginTop: "12px"}}
                onClick={() => this.switchPaymentOptions(PaymentOptions.Account2Account)}
                active={this.state.selectedPaymentOption === PaymentOptions.Account2Account}
              >
                Account 2 Account
              </PaymentOptionButton>
            </Grid.Column>
            {!this.props.checkout.isGifting &&
            <Grid.Column width={5}>
              <PaymentOptionButton
                style={{marginTop: "12px"}}
                onClick={() => this.switchPaymentOptions(PaymentOptions.KiwiplatesLayby)}
                active={this.state.selectedPaymentOption === PaymentOptions.KiwiplatesLayby}
              >
                KiwiPlates Layby
              </PaymentOptionButton>
            </Grid.Column>}
          </>}

        </Responsive>
        <Responsive as={GridColumn} width={16} maxWidth={theme.responsive.mobile.maxWidth}>
          <PaymentOptionRadioButton fluid
            onClick={() => this.switchPaymentOptions(PaymentOptions.CreditCard)}
            active={this.state.selectedPaymentOption === PaymentOptions.CreditCard}
            isFirst={true}
          >
            <PaymentOptionRadio
              checked={this.state.selectedPaymentOption === PaymentOptions.CreditCard} />
            Credit/Debit Card
          </PaymentOptionRadioButton>
          {isDealerOrder ?
           <PaymentOptionRadioButton fluid
             onClick={() => this.switchPaymentOptions(PaymentOptions.DealerPaymentRequest)}
             active={this.state.selectedPaymentOption === PaymentOptions.DealerPaymentRequest}
           >
             <PaymentOptionRadio
               checked={this.state.selectedPaymentOption === PaymentOptions.DealerPaymentRequest} />
             Dealer Payment Request
           </PaymentOptionRadioButton> :
          <>
            <PaymentOptionRadioButton fluid
              onClick={() => this.switchPaymentOptions(PaymentOptions.Account2Account)}
              active={this.state.selectedPaymentOption === PaymentOptions.Account2Account}
            >
              <PaymentOptionRadio
                checked={this.state.selectedPaymentOption === PaymentOptions.Account2Account} />
              Account 2 Account
            </PaymentOptionRadioButton>
            <PaymentOptionRadioButton fluid
              onClick={() => this.switchPaymentOptions(PaymentOptions.Afterpay)}
              active={this.state.selectedPaymentOption === PaymentOptions.Afterpay}
            >
              <PaymentOptionRadio disabled={!isAfterpayAvailable || orderHasPromo} checked={this.state.selectedPaymentOption === PaymentOptions.Afterpay && isAfterpayAvailable} />
              <AfterpayLogo fill={"#000"} />
            </PaymentOptionRadioButton>
            {!this.props.checkout.isGifting &&
              <PaymentOptionRadioButton fluid
              onClick={() => this.switchPaymentOptions(PaymentOptions.KiwiplatesLayby)}
              active={this.state.selectedPaymentOption === PaymentOptions.KiwiplatesLayby}
              // disabled={!this.isLaybyAllowed(basket)}
              isLast={true}
            >
              <PaymentOptionRadio
                checked={this.state.selectedPaymentOption === PaymentOptions.KiwiplatesLayby} />
              KiwiPlates Layby
            </PaymentOptionRadioButton>
            }

          </>}
        </Responsive>


        {(checkout.isLayby && !disablePaymentButton) && (
          <LaybyPaymentContainer
            className={!checkout.isLayby ? "hidden" : ""}
            width={16}
          >
            <LaybyPayment
              apiUrl={this.props.apiUrl}
              checkout={checkout}
              laybyInfo={this.props.data.layby_info}
              basket={basket}
              updateLaybyQuote={this.props.updateLaybyQuote}
              updateLaybyPreferredPaymentDay={
                this.props.updateLaybyPreferredPaymentDay
              }
              updatePaymentType={this.props.updatePaymentType}
              updateIsLayby={this.props.updateIsLayby}
              updateIsAutopay={this.props.updateIsAutopay}
            ></LaybyPayment>
          </LaybyPaymentContainer>
        )}
        {
          this.state.selectedPaymentOption !== PaymentOptions.KiwiplatesLayby &&
          <Responsive maxWidth={theme.responsive.tablet.maxWidth} as={GridColumn} width={16}>
            <OrderTotal paymentOption={this.state.selectedPaymentOption} total={basket.summary.finalTotal} laybyDeposit={checkout.laybyQuote.depositAmount} />
          </Responsive>
        }

        {this.state.selectedPaymentOption === PaymentOptions.CreditCard && (
          <Grid.Column width={16}>
            <Grid>
              {this.props.checkout.isLayby ? (
                <Grid.Column width={16}>
                  {this.props.data.cclaybynote}
                </Grid.Column>
              ) : (
                  <Grid.Column width={16}>{this.props.data.ccnote}</Grid.Column>
                )}
              {!checkout.manufactureNow && (
                <Grid.Column width={16} className="t-and-c">
                  <TermsConditionsCheckbox
                    onChange={this.changeCCManufacture}
                    checked={this.state.ccManufaucture}
                  />
                  <div
                    dangerouslySetInnerHTML={{ __html: this.props.data.manutc }}
                  />
                </Grid.Column>
              )}

              {
                !checkout.isDealerOrder ?
                <Grid.Column width={16}  className="t-and-c">
                  <TermsConditionsCheckbox
                    onChange={this.changeCCAgreeTC}
                    checked={this.state.ccAgreeTC}
                  />{" "}
                  <div dangerouslySetInnerHTML={{ __html: tcLabel }} />
                </Grid.Column>
                :  <Grid.Column width={16}  className="t-and-c">
                    <TermsConditionsCheckbox
                      onChange={this.changeDealerTC}
                      checked={this.state.dealerTC}
                    />
                    <div dangerouslySetInnerHTML={{ __html: this.props.data.dealers_t_c }} />
                  </Grid.Column>
              }
            </Grid>
          </Grid.Column>
        )}
        {this.state.selectedPaymentOption === PaymentOptions.Account2Account && (
          <Grid.Column width={16}>
            <Grid>
              {
                disablePaymentButton ?
                  <Grid.Column width={16}>
                    <Paragraph1 dangerouslySetInnerHTML={{ __html: this.props.data.a2a_disable_note }} />
                  </Grid.Column>
                  :
                  (
                    this.props.checkout.isLayby ? (
                      <Grid.Column width={16}>
                        {this.props.data.aalaybynote}
                      </Grid.Column>
                    ) : (
                        <Grid.Column width={16}>{this.props.data.aanote}</Grid.Column>
                      )
                  )
              }
              {!checkout.manufactureNow && (
                <Grid.Column width={16} className="t-and-c">
                  <TermsConditionsCheckbox
                    onChange={this.changeCCManufacture}
                    checked={this.state.ccManufaucture}
                  />
                  <div
                    dangerouslySetInnerHTML={{ __html: this.props.data.manutc }}
                  />
                </Grid.Column>
              )}
              <Grid.Column width={16} className="t-and-c">
                <TermsConditionsCheckbox
                  onChange={this.changeA2AAgreeTC}
                  checked={this.state.a2aAgreeTC}
                />
                <div dangerouslySetInnerHTML={{ __html: tcLabel }} />
              </Grid.Column>
            </Grid>
          </Grid.Column>
        )}
        {this.state.selectedPaymentOption === PaymentOptions.Gem && (
          <Grid.Column width={16}>
            <Grid>
              {this.props.checkout.isLayby ? (
                <Grid.Column width={16}>
                  {this.props.data.cclaybynote}
                </Grid.Column>
              ) : (
                  <Grid.Column width={16}>
                    <div dangerouslySetInnerHTML={{ __html: this.props.data.gemnote }} />
                  </Grid.Column>
                )}
              {!checkout.manufactureNow && (
                <Grid.Column width={16} className="t-and-c">
                  <TermsConditionsCheckbox
                    onChange={this.changeCCManufacture}
                    checked={this.state.ccManufaucture}
                  />
                  <div
                    dangerouslySetInnerHTML={{ __html: this.props.data.manutc }}
                  />
                </Grid.Column>
              )}

              {
                !checkout.isDealerOrder ?
                <Grid.Column width={16}  className="t-and-c">
                  <TermsConditionsCheckbox
                    onChange={this.changeGemAgreeTC}
                    checked={this.state.gemAgreeTC}
                  />{" "}
                  <div dangerouslySetInnerHTML={{ __html: tcLabel }} />
                </Grid.Column>
                :  <Grid.Column width={16}  className="t-and-c">
                    <TermsConditionsCheckbox
                      onChange={this.changeDealerTC}
                      checked={this.state.dealerTC}
                    />
                    <div dangerouslySetInnerHTML={{ __html: this.props.data.dealers_t_c }} />
                  </Grid.Column>
              }
            </Grid>
          </Grid.Column>
        )}
        {this.state.selectedPaymentOption === PaymentOptions.Afterpay && (
          <Grid.Column width={16}>
            {
              disablePaymentButton ?
                <Grid>
                  <Grid.Column width={16}>
                    <Paragraph1 dangerouslySetInnerHTML={{ __html: this.props.data.afterpay_disable_note }} />
                  </Grid.Column>
                </Grid>
                :
                <Grid>
                  <Grid.Column width={16}>{this.props.data.afterpay_note}</Grid.Column>
                  {!checkout.manufactureNow && (
                    <Grid.Column width={16} className="t-and-c">
                      <TermsConditionsCheckbox
                        onChange={this.changeCCManufacture}
                        checked={this.state.ccManufaucture}
                      />
                      <div
                        dangerouslySetInnerHTML={{ __html: this.props.data.manutc }}
                      />
                    </Grid.Column>
                  )}
                  <Grid.Column width={16} className="t-and-c">
                    <TermsConditionsCheckbox
                      onChange={this.changeAfterpayAgreeTC}
                      checked={this.state.afterpayAgreeTC}
                    />
                    <div dangerouslySetInnerHTML={{ __html: tcLabel }} />
                  </Grid.Column>
                </Grid>
            }

          </Grid.Column>
        )}
        {this.state.selectedPaymentOption === PaymentOptions.KiwiplatesLayby && (
          <Grid.Column width={16}>
            {
              disablePaymentButton ?
                <Grid>
                  <Grid.Column width={16}>
                    <Paragraph1 dangerouslySetInnerHTML={{ __html: this.props.data.layby_disable_note }} />
                  </Grid.Column>
                </Grid>
                :
                <Grid>
                  <Grid.Column width={16}>
                    {this.props.data.cclaybynote}
                  </Grid.Column>
                  {!checkout.manufactureNow && (
                    <Grid.Column width={16} className="t-and-c">
                      <TermsConditionsCheckbox
                        onChange={this.changeCCManufacture}
                        checked={this.state.ccManufaucture}
                      />
                      <div
                        dangerouslySetInnerHTML={{ __html: this.props.data.manutc }}
                      />
                    </Grid.Column>
                  )}

                  <Grid.Column width={16} className="t-and-c">
                    <TermsConditionsCheckbox
                      onChange={this.changeCCAgreeTC}
                      checked={this.state.ccAgreeTC}
                    />{" "}
                    <div dangerouslySetInnerHTML={{ __html: tcLabel }} />
                  </Grid.Column>
                  <Grid.Column width={16} className="t-and-c">
                    <TermsConditionsCheckbox
                      onChange={() => this.changeAutopay(!checkout.isAutopay)}
                      checked={checkout.isAutopay}
                    />
                    <div
                      dangerouslySetInnerHTML={{ __html: this.props.data.layby_autopay_tc }}
                    />
                    {/* <Checkbox
                  onChange={() => this.changeAutopay(!checkout.isAutopay)}
                  checked={checkout.isAutopay}
                  label="I hereby authorise KiwiPlates to deduct the remaining Layby payments (recurring payments), using the credit/debit card I will use now to make the deposit payment."
                /> */}
                  </Grid.Column>

                </Grid>
            }

          </Grid.Column>
        )}
        {isDealerOrder && this.state.selectedPaymentOption == PaymentOptions.DealerPaymentRequest && (
          <Grid.Column width={16}>
            <Grid>
            <Grid.Column width={16}>{this.props.data.dealer_payment_request_note}</Grid.Column>
            {!checkout.manufactureNow && (
                <Grid.Column width={16} className="t-and-c">
                  <TermsConditionsCheckbox
                    onChange={this.changeCCManufacture}
                    checked={this.state.ccManufaucture}
                  />
                  <div
                    dangerouslySetInnerHTML={{ __html: this.props.data.manutc }}
                  />
                </Grid.Column>
              )}
              <Grid.Column width={16} className="t-and-c">
                <TermsConditionsCheckbox
                  onChange={this.changeDealerTC}
                  checked={this.state.dealerTC}
                />
                <div dangerouslySetInnerHTML={{ __html: this.props.data.dealers_t_c }} />
              </Grid.Column>
              <Grid.Column width={16} className="t-and-c">
                <TermsConditionsCheckbox
                  onChange={this.changeDealerPaymentRequestTC}
                  checked={this.state.dealerPaymentRequestTC}
                />{" "}
                <div dangerouslySetInnerHTML={{ __html: this.props.data.dealer_payment_request_t_c }} />
              </Grid.Column>
            </Grid>
          </Grid.Column>
        )}
        <Grid>
          {this.state.showError && (
            <Grid.Column width={16}>
              <Paragraph2 color="red">
                Please read and agree to the terms above before you can proceed
                to payment.
              </Paragraph2>
            </Grid.Column>
          )}
        </Grid>
        <Grid.Column width={16}>
          <SummaryActionGrid reversed="mobile vertically">
            <SummaryActionColumn
              mobile={16}
              tablet={8}
              computer={8}
              largeScreen={8}
              widescreen={8}
            >
              <KpButton
                id="fp-back"
                fullWidth="mobile"
                buttonType="secondary"
                color={theme.brand.colors.black}
                onClick={this.goBackClick}
              >
                BACK
              </KpButton>
            </SummaryActionColumn>
            <SummaryActionColumn
              mobile={16}
              tablet={8}
              computer={8}
              largeScreen={8}
              widescreen={8}
              textAlign="right"
            >
              <KpButton
                id="fp-payment"
                fullWidth="mobile"
                disabled={this.state.processing || (disablePaymentButton && this.state.selectedPaymentOption != PaymentOptions.Account2Account)}
                loading={this.state.processing}
                buttonType="primary"
                color={theme.brand.colors.blue}
                onClick={this.placeOrder}
              >
                {
                  this.state.selectedPaymentOption === PaymentOptions.Afterpay ?
                    "PAY NOW WITH AFTERPAY"
                    :
                    (
                      (this.state.selectedPaymentOption === PaymentOptions.Account2Account && disablePaymentButton
                        || this.state.selectedPaymentOption === PaymentOptions.DealerPaymentRequest) ? "PLACE ORDER" : "GO TO PAYMENT"
                    )
                }
              </KpButton>
              {this.state.processing && (
                <div>
                  <Legal color="#707070">Loading Please wait</Legal>
                </div>
              )}
            </SummaryActionColumn>
            {this.state.error !== "" && (
              <InfoModal
                modalOpen={true}
                title="ERROR"
                content={this.state.error}
                onClose={this.state.onErrorModalClose}
              />
            )}
          </SummaryActionGrid>
        </Grid.Column>
      </Grid>
    )
  }
}

const mapStateToProps = (state: Record<any>) => { return state.toJS(); }
const mapDispatchToProps = (dispatch: Dispatch) => ({})

const FullPayment = connect(
  mapStateToProps,
  mapDispatchToProps
)(FullPaymentContainer)

export default FullPayment